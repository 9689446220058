<template>
  <div class="order-detail d-flex flex-column scroll-container">
    <v-container class="d-flex flex-column scroll-container" v-if="order">
      <CategoryTitle :category="category" />
      <p
        v-if="deliveryServiceWithReplaceableItems && showGlobal()"
        class="subtitle-2  d-block pt-3 pt-md-0"
      >
        <span v-html="$t('order.replaceableClaim')"></span>
      </p>
      <OrderDetailInfo
        :order="order"
        @addAllToCart="addAllToCart"
        @deleteOrder="deleteOrder"
        @editOrder="editOrder"
        @showPayment="showPayment = !showPayment"
      />
      <PaymentTypeList
        mode="order"
        :order-id="order.orderId"
        v-if="showPayment"
        :options="paymentTypeListOptions"
      />

      <v-row class="justify-space-between">
        <v-col col="12" sm="5">
          <CartInfoAddressCard :shippingAddress="order.shippingAddress" />
          <CartInfoTimeslotCard
            :shippingAddress="order.shippingAddress"
            :timeslot="order.timeslot"
          />
        </v-col>
        <v-col col="12" sm="7">
          <CheckoutSummary
            :orderCart="order"
            :isOrderView="true"
            :isCheckout="false"
          />
        </v-col>
      </v-row>

      <OrderDetailTable
        :delivered="delivered"
        :suborders="order.suborders"
        :showReplaceable="deliveryServiceWithReplaceableItems"
      ></OrderDetailTable>

      <v-row class="actions-buttons footer-btn">
        <v-btn
          outlined
          color="primary"
          class="bg-white"
          x-large
          depressed
          v-bind:to="'/profile/orders'"
        >
          TORNA AI MIEI ORDINI
        </v-btn>
      </v-row>
    </v-container>
    <v-spacer class="spacer-scroll"></v-spacer>
  </div>
</template>
<script>
// @ is an alias to /src
import OrderDetailTable from "@/components/orders/OrderDetailTable";
import OrderDetailInfo from "@/components/orders/OrderDetailInfo";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard";
import CheckoutSummary from "@/components/cart/CheckoutSummary";
import CategoryTitle from "@/components/category/CategoryTitle";
import PaymentTypeList from "~/components/payment/PaymentTypeList";

import OrderService from "~/service/orderService";
import AnalyticsService from "~/service/analyticsService";

import categoryMixins from "~/mixins/category";
import reload from "~/mixins/reload";
import login from "~/mixins/login";

import { mapState, mapActions } from "vuex";
import get from "lodash/get";
export default {
  name: "Order",
  data() {
    return {
      review: {
        rating: 3,
        comment: "",
        active: 1,
        title: "",
        noteId: null,
        productId: -1
      },
      order: null,
      valid: true,
      showPayment: false,
      requiredRules: [v => !!v || "Campo obbligatorio"],
      paymentTypeListOptions: {
        flatPanels: true,
        showCardLogos: true,
        titleStyle: "default--text text-uppercase payment-type-list-title",
        prevButton: {
          type: "link-button",
          label: "payment.backToCheckout",
          class: "text-none d-none",
          options: {
            icon: "$prev",
            iconClass: "primary white--text rounded-lg mr-3",
            textClass: "primary--text text-caption font-weight-bold"
          }
        }
      }
    };
  },
  mixins: [categoryMixins, reload, login],
  components: {
    OrderDetailInfo,
    OrderDetailTable,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    CheckoutSummary,
    CategoryTitle,
    PaymentTypeList
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    canIRateOrder() {
      return this.order.orderStatusId === 8 || this.order.orderStatusId === 18;
    },
    enableSave() {
      return !this.valid;
    },
    shippingAddress() {
      let shippingAddress = get(this.order, "shippingAddress");
      if (shippingAddress) {
        return this.$t(
          "navbar.address." + shippingAddress.addressTypeId + ".format",
          shippingAddress
        );
      } else {
        return this.$t("navbar.noShippingAddress");
      }
    },
    deliveryServiceWithReplaceableItems() {
      return global.config.deliveryServiceWithAlternativesProduct.includes(
        this.order.shippingAddress.deliveryServiceId
      );
    },

    delivered() {
      // return false;
      //decommentare per mostrare i campi delivered
      return (
        this.order.orderStatusId == 6 ||
        this.order.orderStatusId == 8 ||
        this.order.orderStatusId == 18
      );
    },
    // timeSlot() {
    //   if (this.order.timeslot) {
    //     // eslint-disable-next-line no-debugger
    //     debugger;
    //     const date = this.$dayjs(this.order.timeslot.date).format("DD MMMM");
    //     return (
    //       date +
    //       " | " +
    //       this.getTimeslotTime(this.order.timeslot.beginTime) +
    //       " - " +
    //       this.getTimeslotTime(this.order.timeslot.endTime)
    //     );
    //   }
    //   return this.$t("navbar.noTimeslot");
    // },
    serviceImg() {
      return this.$t(
        "navbar.service." +
          this.order.shippingAddress.deliveryServiceId +
          ".icon",
        {
          color: "color"
        }
      );
    }
  },
  methods: {
    // getTimeslotTime(time) {
    //   if (time) {
    //     const parseTime = this.am_pm_to_hours(time);
    //     var d = new Date();
    //     var timeMatch = parseTime.match(/(\d+)(?::(\d\d))?\s*(p?)/);
    //     d.setHours(parseInt(timeMatch[1]) + (timeMatch[3] ? 12 : 0));
    //     d.setMinutes(parseInt(timeMatch[2]) || 0);
    //     const timeOnly = this.$dayjs(d).format("HH:mm");
    //     return timeOnly;
    //   } else return "";
    // },
    am_pm_to_hours(time) {
      var hours = Number(time.match(/^(\d+)/)[1]);
      var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM == "PM" && hours < 12) hours = hours + 12;
      if (AMPM == "AM" && hours == 12) hours = hours - 12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      return sHours + ":" + sMinutes;
    },
    ...mapActions({
      addProductsFromOrder: "cart/addProductsFromOrder",
      loadCart: "cart/loadCart"
    }),
    async addAllToCart() {
      if (await this.needLogin("addAllToCart")) {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            let res = await global.vm.$dialog.confirm({
              text: global.vm.$t("message.addAllFromOrder")
            });
            if (res) {
              await this.addProductsFromOrder(this.order.orderId);
            }
          }
        }
      }
    },
    async deleteOrder() {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeOrder", {
          orderId: this.order.orderId
        })
      });

      if (res) {
        await OrderService.deleteOrder(this.order.orderId);
        AnalyticsService.refund(this.order);
        await this.reload(this.$route.params.orderId);
      }
    },
    async editOrder() {
      await OrderService.editOrder(this.order.orderId);
      this.loadCart();
      this.$router.push({
        name: "Checkout"
      });
    },
    async reload() {
      this.order = await OrderService.getOrder(this.$route.params.orderId);
    },
    showGlobal() {
      let globals = [];
      this.order.suborders.forEach(suborder => {
        suborder.suborderItems.forEach(suborderItem => {
          if (get(suborderItem, "product.productInfos.IS_REPLACEABLE")) {
            if (
              get(suborderItem, "product.productInfos.IS_REPLACEABLE") ==
                "true" &&
              suborderItem.orderItemInfos.accept_alternatives == "true"
            ) {
              globals.push(suborderItem);
            }
          }
        });
      });
      if (globals.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    this.reload(this.$route.params.orderId);
  }
};
</script>
