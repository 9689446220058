<template>
  <div class="detail-table t-order-detail">
    <v-row
      no-gutters
      v-if="!$vuetify.breakpoint.smAndDown"
      class="table-header"
    >
      <v-col cols="6" sm="6">
        <strong>Prodotto</strong>
      </v-col>

      <v-col cols="2" sm="2" class="d-flex justify-center">
        <strong>Prezzo unitario</strong>
      </v-col>
      <v-col cols="2" sm="2" class="d-flex justify-center">
        <strong>{{ $vuetify.breakpoint.xs ? "Qt." : "Quantità" }}</strong>
      </v-col>
      <v-col cols="2" sm="2" class="d-flex justify-center">
        <strong>Prezzo finale</strong>
      </v-col>
    </v-row>

    <v-list
      v-for="suborder in suborders"
      v-bind:key="suborder.suborderId"
      class="t-detail-list"
    >
      <v-list-item
        v-for="item in suborder.suborderItems"
        v-bind:key="item.itemId"
        :to="{ name: 'Product', params: { slug: item.product.slug } }"
      >
        <v-row no-gutters align="center">
          <v-col cols="12" md="6" class="d-flex align-center mb-3 mb-md-0">
            <template
              v-if="
                showReplaceable &&
                  item.product.productInfos.IS_REPLACEABLE &&
                  item.product.productInfos.IS_REPLACEABLE == 'true' &&
                  item.orderItemInfos.accept_alternatives == 'true'
              "
            >
              <v-icon
                :color="
                  item.orderItemInfos.accept_alternatives == 'true'
                    ? 'primary'
                    : '#ccc'
                "
                class="accept-alternative-icon"
              >
                $replace
              </v-icon>
            </template>
            <v-img
              :src="item.product.mediaURL"
              class="product-img"
              width="85px"
              height="85px"
              max-height="85px"
              max-width="85px"
              :class="{
                'ml-8': showReplaceable && acceptAlternatives
              }"
            />
            <div class="product-info">
              <span class="product-brand">{{ item.product.shortDescr }}</span>
              <span class="product-name">{{ item.product.name }}</span>
              <div class="product-descr">{{ item.product.description }}</div>
            </div>
          </v-col>

          <v-col cols="4" sm="2" class="d-flex justify-center">
            <OrderItemPrice :item="item" :showWeightUnitBase="true" />
          </v-col>
          <v-col cols="4" sm="2" class="d-flex justify-center">
            <OrderDetailQuantity :item="item" :delivered="delivered" />
          </v-col>
          <v-col cols="4" sm="2" offset-sm="0" class="d-flex justify-center">
            <OrderGrossTotal :item="item"></OrderGrossTotal>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>
<style lang="scss">
.detail-table {
  .accept-alternative-icon {
    position: absolute;
    z-index: 1;
    margin-top: 0px;
    margin-left: 2px;
  }
}
</style>
<script>
import OrderItemPrice from "@/components/orders/OrderItemPrice.vue";
import OrderDetailQuantity from "@/components/orders/OrderDetailQuantity.vue";
import OrderGrossTotal from "@/components/orders/OrderGrossTotal.vue";

import { mapGetters } from "vuex";

import get from "lodash/get";

export default {
  name: "OrderDetailTable",
  props: ["suborders", "delivered", "showReplaceable"],
  components: { OrderItemPrice, OrderDetailQuantity, OrderGrossTotal },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getDeliveryServiceId: "cart/getDeliveryServiceId"
    }),
    acceptAlternatives: {
      get() {
        let value = false;
        var flaggables = [];
        this.suborders.forEach(suborder => {
          suborder.suborderItems.forEach(suborderItem => {
            if (get(suborderItem, "product.productInfos.IS_REPLACEABLE")) {
              if (
                get(suborderItem, "product.productInfos.IS_REPLACEABLE") ==
                "true"
              ) {
                flaggables.push(suborderItem);
              }
              flaggables.forEach(flaggable => {
                if (
                  get(
                    flaggable,
                    "orderItemInfos.accept_alternatives",
                    "true"
                  ) == "true"
                ) {
                  value = true;
                } else {
                  value = false;
                  return;
                }
              });
            }
            return value;
          });
        });
        return value;
      }
    }
  },

  methods: {},
  mounted() {
    // this.fetchCommentOrder();
    //  Per Iperal, dentro OrderItemPrice, viene mostrato il weightUnitBase;
    //  weightUnitDisplay sarebbe sbagliato (è una cosa che ha solo Iperal)
  }
};
</script>
