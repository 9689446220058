var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-table t-order-detail"},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-row',{staticClass:"table-header",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('strong',[_vm._v("Prodotto")])]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"2","sm":"2"}},[_c('strong',[_vm._v("Prezzo unitario")])]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"2","sm":"2"}},[_c('strong',[_vm._v(_vm._s(_vm.$vuetify.breakpoint.xs ? "Qt." : "Quantità"))])]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"2","sm":"2"}},[_c('strong',[_vm._v("Prezzo finale")])])],1):_vm._e(),_vm._l((_vm.suborders),function(suborder){return _c('v-list',{key:suborder.suborderId,staticClass:"t-detail-list"},_vm._l((suborder.suborderItems),function(item){return _c('v-list-item',{key:item.itemId,attrs:{"to":{ name: 'Product', params: { slug: item.product.slug } }}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"d-flex align-center mb-3 mb-md-0",attrs:{"cols":"12","md":"6"}},[(
              _vm.showReplaceable &&
                item.product.productInfos.IS_REPLACEABLE &&
                item.product.productInfos.IS_REPLACEABLE == 'true' &&
                item.orderItemInfos.accept_alternatives == 'true'
            )?[_c('v-icon',{staticClass:"accept-alternative-icon",attrs:{"color":item.orderItemInfos.accept_alternatives == 'true'
                  ? 'primary'
                  : '#ccc'}},[_vm._v(" $replace ")])]:_vm._e(),_c('v-img',{staticClass:"product-img",class:{
              'ml-8': _vm.showReplaceable && _vm.acceptAlternatives
            },attrs:{"src":item.product.mediaURL,"width":"85px","height":"85px","max-height":"85px","max-width":"85px"}}),_c('div',{staticClass:"product-info"},[_c('span',{staticClass:"product-brand"},[_vm._v(_vm._s(item.product.shortDescr))]),_c('span',{staticClass:"product-name"},[_vm._v(_vm._s(item.product.name))]),_c('div',{staticClass:"product-descr"},[_vm._v(_vm._s(item.product.description))])])],2),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"4","sm":"2"}},[_c('OrderItemPrice',{attrs:{"item":item,"showWeightUnitBase":true}})],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"4","sm":"2"}},[_c('OrderDetailQuantity',{attrs:{"item":item,"delivered":_vm.delivered}})],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"4","sm":"2","offset-sm":"0"}},[_c('OrderGrossTotal',{attrs:{"item":item}})],1)],1)],1)}),1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }