<template>
  <v-card class="order-info">
    <v-card-title>
      <v-row no-gutters>
        <v-col cols="4" md="2">
          <span>N° Ordine</span>
        </v-col>
        <v-col cols="4" md="2">
          <span>Stato</span>
        </v-col>
        <v-col cols="4" md="2">
          <span>Fattura</span>
        </v-col>
        <v-col cols="12" md="6"> </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row no-gutters class="align-center">
        <v-col cols="4" md="2">
          <span class="order-number">#{{ order.orderId }}</span>
        </v-col>
        <v-col cols="4" md="2">
          <OrderStatusBadge v-bind:order="order" />
        </v-col>
        <v-col cols="4" md="2">
          {{ order.orderInfos.need_invoice === "true" ? "Si" : "No" }}
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-md-end justify-start">
          <OrderQrCode
            v-if="order.orderInfos.PIN_CODE"
            :codeValue="order.orderInfos.PIN_CODE"
            :width="40"
            :height="40"
          />
          <v-tooltip bottom v-if="order.orderStatusId == 8">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                large
                v-bind="attrs"
                v-on="on"
                icon
                class="main-button mr-1"
                target="_blank"
                :href="receiptUrl"
                @click.prevent="
                  handleReceiptDownload(receiptUrl, order.orderId)
                "
              >
                <v-icon>mdi-receipt</v-icon>
              </v-btn>
            </template>
            <span>Scarica fattura o scontrino</span>
          </v-tooltip>
          <v-tooltip bottom v-if="order.isEditable">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                large
                v-bind="attrs"
                v-on="on"
                icon
                class="main-button mr-1"
                @click="editOrder(order.orderId)"
              >
                <v-icon>$edit</v-icon>
              </v-btn>
            </template>
            <span>Modifica ordine</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="order.orderStatusId == 8 || order.orderStatusId == 10"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                large
                v-bind="attrs"
                v-on="on"
                icon
                class="main-button mr-1"
                @click="addAllToCart(order.orderId)"
              >
                <v-icon>$cart</v-icon>
              </v-btn>
            </template>
            <span>Aggiungi tutto al carrello</span>
          </v-tooltip>

          <v-tooltip
            bottom
            v-if="
              order.orderStatusId != 11 &&
                !(order.orderStatusId == 1 && order.paymentTypeId == 1)
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                large
                icon
                class="main-button mr-1"
                @click="printOrder"
              >
                <v-icon>$print</v-icon>
              </v-btn>
            </template>
            <span>Stampa ordine</span>
          </v-tooltip>
          <v-tooltip bottom v-if="order.isPayable">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                large
                icon
                class="main-button"
                @click="showPayment"
              >
                <v-icon>mdi-credit-card-outline</v-icon>
              </v-btn>
            </template>
            <span>Ritenta pagamento</span>
          </v-tooltip>

          <v-tooltip bottom v-if="order.isDeletable">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                large
                icon
                class="secondary-icon-button ml-1"
                @click="deleteOrder(order.orderId)"
              >
                <v-icon>$delete</v-icon>
              </v-btn>
            </template>
            <span>Annulla ordine</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge";
import OrderQrCode from "./OrderQrCode";
import downloadDocumentMixin from "~/mixins/downloadDocument";
import login from "~/mixins/login";

export default {
  name: "OrderDetailInfo",
  mixins: [downloadDocumentMixin, login],
  components: { OrderStatusBadge, OrderQrCode },
  props: ["order"],
  computed: {
    receiptUrl() {
      let documentTypeId =
        this.order.warehouse.warehouseId == global.config.dksId ? 12 : 1;
      let orderId = this.order.orderId;
      return `/ebsn/api/order/document?document_type_id=${documentTypeId}&type=pdf&order_id=${orderId}`;
    }
  },
  methods: {
    async addAllToCart(orderId) {
      if (await this.needLogin("addAllToCart")) {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            this.$emit("addAllToCart", {
              orderId
            });
          }
        }
      }
    },
    deleteOrder(orderId) {
      this.$emit("deleteOrder", {
        orderId
      });
    },
    async editOrder(orderId) {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("order.editOrder", {
          orderId: orderId
        }),
        width: 575
      });
      if (res) {
        this.$emit("editOrder", {
          orderId
        });
      }
    },
    printOrder() {
      if (this.isCordova) {
        window.cordova.plugins.printer.print();
      } else {
        window.print();
      }
    },
    showPayment() {
      this.$emit("showPayment");
    }
  }
};
</script>
