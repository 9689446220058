var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"order-info"},[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('span',[_vm._v("N° Ordine")])]),_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('span',[_vm._v("Stato")])]),_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('span',[_vm._v("Fattura")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}})],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('span',{staticClass:"order-number"},[_vm._v("#"+_vm._s(_vm.order.orderId))])]),_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('OrderStatusBadge',{attrs:{"order":_vm.order}})],1),_c('v-col',{attrs:{"cols":"4","md":"2"}},[_vm._v(" "+_vm._s(_vm.order.orderInfos.need_invoice === "true" ? "Si" : "No")+" ")]),_c('v-col',{staticClass:"d-flex justify-md-end justify-start",attrs:{"cols":"12","md":"6"}},[(_vm.order.orderInfos.PIN_CODE)?_c('OrderQrCode',{attrs:{"codeValue":_vm.order.orderInfos.PIN_CODE,"width":40,"height":40}}):_vm._e(),(_vm.order.orderStatusId == 8)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"main-button mr-1",attrs:{"large":"","icon":"","target":"_blank","href":_vm.receiptUrl},on:{"click":function($event){$event.preventDefault();return _vm.handleReceiptDownload(_vm.receiptUrl, _vm.order.orderId)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-receipt")])],1)]}}],null,false,1418443051)},[_c('span',[_vm._v("Scarica fattura o scontrino")])]):_vm._e(),(_vm.order.isEditable)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"main-button mr-1",attrs:{"large":"","icon":""},on:{"click":function($event){return _vm.editOrder(_vm.order.orderId)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$edit")])],1)]}}],null,false,1160822362)},[_c('span',[_vm._v("Modifica ordine")])]):_vm._e(),(_vm.order.orderStatusId == 8 || _vm.order.orderStatusId == 10)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"main-button mr-1",attrs:{"large":"","icon":""},on:{"click":function($event){return _vm.addAllToCart(_vm.order.orderId)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$cart")])],1)]}}],null,false,3396605871)},[_c('span',[_vm._v("Aggiungi tutto al carrello")])]):_vm._e(),(
            _vm.order.orderStatusId != 11 &&
              !(_vm.order.orderStatusId == 1 && _vm.order.paymentTypeId == 1)
          )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"main-button mr-1",attrs:{"large":"","icon":""},on:{"click":_vm.printOrder}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$print")])],1)]}}],null,false,3637950239)},[_c('span',[_vm._v("Stampa ordine")])]):_vm._e(),(_vm.order.isPayable)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"main-button",attrs:{"large":"","icon":""},on:{"click":_vm.showPayment}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-credit-card-outline")])],1)]}}],null,false,1290818491)},[_c('span',[_vm._v("Ritenta pagamento")])]):_vm._e(),(_vm.order.isDeletable)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"secondary-icon-button ml-1",attrs:{"large":"","icon":""},on:{"click":function($event){return _vm.deleteOrder(_vm.order.orderId)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$delete")])],1)]}}],null,false,3163151379)},[_c('span',[_vm._v("Annulla ordine")])]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }