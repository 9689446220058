<template>
  <span class="status" v-if="order">{{ orderStatus }}</span>
</template>
<style global lang="scss">
.status {
  color: #f39300;
  font-weight: bold;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: 13px;
  }
}
</style>
<script>
export default {
  props: ["order"],
  computed: {
    orderStatus() {
      return global.vm.$t(
        `order.orderStatusDescr[${this.order.orderStatusId}]`
      );
    }
  }
};
</script>
